import { createI18n } from "vue-i18n";
import zh_tw from "./zh_tw.json";
import en from "./en.json";

let locale

// 判斷之前是否有改過語系, 讀取紀錄
if (localStorage.getItem("i18nLang")) {
    const localeData = localStorage.getItem("i18nLang");
    locale = localeData ? localeData : "zh-TW";
} else {
    localStorage.setItem("i18nLang", locale);
}

const i18n = new createI18n({
    locale: locale,
    messages: {
        "zh-TW": zh_tw,
        "ja-JP": {
            "welcome": "ようこそ"
        },
        "en-US": en,

    },
    fallbackLocale: "en-US",
});

export { i18n };