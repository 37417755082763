<template>
    <div class="basic-settings">
        <div class="content-block">
            <router-view />
        </div>

    </div>
</template>

<script>
import WOW from 'wow.js';

export default {
    name: 'app',
    mounted() {
        let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

        if (!isMobile) {
            new WOW().init();
        }

    },
    created() {
        let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        this.isPC = !isMobile
    },
    data() {
        return {
            isPC: null,
        }
    },
}
</script>

<style>
@font-face {
    font-family: 'METROPOLIS-LIGHT';
    src: url('/src/assets/fonts/METROPOLIS-LIGHT.OTF');
}

@font-face {
    font-family: 'METROPOLIS-SEMIBOLD';
    src: url('/src/assets/fonts/METROPOLIS-SEMIBOLD.OTF');
}

.basic-settings {
    font-family: METROPOLIS-LIGHT, Helvetica, Arial, sans-serif;

    .Semi-bold {
        font-family: METROPOLIS-SEMIBOLD, Helvetica, Arial, sans-serif;
    }

    .content-block {
        width: 100%;
        min-width: 1280px;
        max-width: 2048px;
        overflow: hidden;
    }

    .div-all-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: 1800px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.header-banner {
    padding: 0px 50px 0px 260px;

    @media (max-width: 1800px) {
        padding: 0px 50px 0px 145px;
    }
}

.main-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -20px;
    background-color: #F6F7F9;
    position: relative;
    z-index: 2;
}
</style>
