import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "@/i18n";

//import regional from "./services/regional"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'swiper/css';
import 'swiper/css/effect-fade';

//await regional();

createApp(App)
    .use(router)
    .use(i18n)
    .mount("#app");


