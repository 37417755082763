import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/elements/404.vue')
    },
    {
        path: '/',
        name: 'LandingPage',
        component: () => import('../views/index.vue')
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import('../views/Company/AboutUs.vue')
    },
    {
        path: '/how-work',
        name: 'howWorks',
        component: () => import('../views/Technology/HowWork.vue')
    },
    {
        path: '/electrocardiogram',
        name: 'Electrocardiogram',
        component: () => import('../views/Technology/Electrocardiogram.vue')
    },
    {
        path: '/sleep-apnea',
        name: 'PerformanceOsa',
        component: () => import('../views/Technology/SleepApnea.vue')
    },
    {
        path: '/rooti-care',
        name: 'RootiCare',
        component: () => import('../views/Service/RootiCare.vue')
    },
    {
        path: '/rooti-rx',
        name: 'RootiRx',
        component: () => import('../views/Service/RootiRx.vue')
    },
    {
        path: '/security',
        name: 'Security',
        component: () => import('../views/Security/Security.vue')
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/Security/PrivacyPolicy.vue')
    },
    {
        path: '/faq',
        name: 'ForPatients',
        component: () => import('../views/Resources/FAQ.vue')
    },
    {
        path: '/medical-papers',
        name: 'MedicalPapers',
        component: () => import('../views/Resources/MedicalPapers.vue')
    },
    {
        path: '/publications/:type',
        name: 'Publications',
        component: () => import('../views/Resources/Publications.vue')
    },
    {
        path: '/rooti-kiosk',
        name: 'RootiKiosk',
        component: () => import('../views/Contribution/RootiKiosk.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to, from) => {
    window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
    next()
})


export default router;
